// Projects.jsx
import React, { useRef } from 'react'
import { ConstructionSign } from '../components/ConstructionSign';

export default function Projects({ lightTheme }) {
    const theme = lightTheme ? "light" : "dark";
    const titleRef = useRef(null);

    return (
        <div id="projects" className={"Content-area " + theme}>
            <div className='Inner'>            
                <h2 ref={titleRef}>
                    <span role="img" aria-label="emoji">
                        🚧
                    </span> Projects 
                    <span role="img" aria-label="emoji">
                        🚧
                    </span>
                </h2>
                <p>
                    Go check my projects from my
                    &nbsp;
                    <a href="https://www.github.com/johmes">
                        GitHub
                    </a>
                </p>
            </div>
        </div>
    )
}